export const userRoles = [
  {
    code: "user",
    label: "User"
  },
  {
    code: "manager",
    label: "Manager"
  },
  {
    code: "admin",
    label: "Admin"
  },
];

export const departmentRegions = ["brussels"];

export const jobRegions = [
  "antwerp",
  "brussels",
  "paris",
  "flemish_brabant",
  "walloon_brabant",
  "limburg",
  "east_flanders",
  "west_flanders",
  "hainaut",
  "liege",
  "luxembourg",
  "namur",
  "ille-et-vilaine",
  "loire-atlantique",
  "hauts-de-france"
];

export const frenchRegions = ["paris", "ille-et-vilaine", "loire-atlantique", "hauts-de-france"];
export const belgianRegions = jobRegions.filter(region => !frenchRegions.includes(region));

export const flandersRegions = ["antwerp", "flemish_brabant", "limburg", "east_flanders", "west_flanders"];

export const walloniaRegions = ["walloon_brabant", "hainaut", "liege", "luxembourg", "namur"];

export const jobStatuses = ["employee", "freelance", "part-time", "independant", "internship", "student-job"];

export const offerLanguages = [
  { code: "fr", value: "French" },
  { code: "nl", value: "Dutch"},
  { code: "en", value: "English"},
];

export const languages = [
  { code: "fr", value: "French" },
  { code: "nl", value: "Dutch"},
  { code: "en", value: "English"},
];

export const adminMenuEntries = ["Dashboard", "Profile", "Departments", "Employees", "Jobs", "Resumes", "Logout"];
export const userMenuEntries = ["Dashboard", "Resumes", "Profile", "Logout"];

export const statusChipColor = {
  draft: "warning",
  scheduled: "info",
  online: "success",
  archive: "default"
};
