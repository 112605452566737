import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import slugify from "slugify";
import sh from "bundles/common/utils/sh";
import BeatLoader from "react-spinners/BeatLoader";

const SideForm = ({ jobOffer }) => {
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_phone: "",
    candidate_linkedin_url: "",
    message: "",
    resume: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const data = {
    subtitle: intl.formatMessage({ id: "side_form.apply_now" }),
    title: intl.formatMessage({ id: "side_form.title" })
  };
  const documentUrls = {
    privacyPolicy: {
      en: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Privacy+Policy+EN.pdf",
      nl: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Privacybeleid+-+NL.pdf",
      fr: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Politique+de+confidentialite%CC%81+FR.pdf",
    },
    terms: {
      en: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Terms+Wesbite+EN.pdf",
      fr: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Conditions+ge%CC%81ne%CC%81rales+Site+FR.pdf",
      nl: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Algemene+Gebruiksvoorwaarden+NL.pdf",
    },
  };

  const formPrivacyLabel = `
    ${intl.formatMessage({ id: "form.agree_to" })}&nbsp;
    <a href="${documentUrls.terms[i18nLocale] || documentUrls.terms.en}"
      target="_blank"
      style="color: #356DE0; text-decoration: underline;"
      onmouseover="this.style.color='darkblue'"
      onmouseout="this.style.color='#356DE0'">${intl.formatMessage({ id: "form.sander_conditions" })}</a>&nbsp;
    ${intl.formatMessage({ id: "form.and" })}&nbsp;
    <a href="${documentUrls.privacyPolicy[i18nLocale] || documentUrls.privacyPolicy.en}"
      target="_blank"
      style="color: #356DE0; text-decoration: underline;"
      onmouseover="this.style.color='darkblue'"
      onmouseout="this.style.color='#356DE0'">${intl.formatMessage({ id: "form.sander_privacy" })}</a>
  `;

  const handleChange = (e) => {
    if (e.target.name === "resume") {
      setFormData({ ...formData, resume: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const required = intl.formatMessage({ id: "form.error.required", defaultMessage: "Required" });

    if (!formData.candidate_name.trim()) {
      errors.candidate_name = required;
      formIsValid = false;
    }

    if (!formData.candidate_email.trim()) {
      errors.candidate_email = required;
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.candidate_email)) {
      errors.candidate_email = intl.formatMessage({ id: "form.error.email_invalid", defaultMessage: "Invalid Email" });
      formIsValid = false;
    }

    if (!formData.candidate_phone.trim()) {
      errors.candidate_phone = required;
      formIsValid = false;
    }

    if (!formData.resume) {
      errors.resume = required;
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(true);

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(`job_application[${key}]`, value);
    });
    data.append("job_application[job_offer_id]", jobOffer.id);
    data.append("authenticity_token", authenticityToken);

    sh.post("/job_applications", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => {
        enqueueSnackbar(intl.formatMessage({ id: "side_form.successfully_applied" }), { variant: "success" });
        setTimeout(() => {
          setIsSubmitting(false);
          const trackingUrl = `${window.location.pathname}?applied=true`;
          window.location.href = trackingUrl;
        }, 2400);
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div id="sideForm" className="fixed top-0 right-0 max-h-[unset] max-w-[unset] h-screen overflow-scroll w-full mini:vw-w mini:[--w:500] tablet:[--w:696] laptop:[--w:828] desktop:[--w:988] m-0 ml-auto backdrop:bg-deepBlue/20 vw-px [--px:16] mini:[--px:64] tablet:[--px:84] laptop:[--px:108] desktop:[--px:124] vw-pt [--pt:160] vw-pb [--pb:64] mini:vw-py [--py:110] laptop:[--py:120] desktop:[--py:160] bg-white text-darkGreen flex flex-col vw-gap [--gap:24] z-50 transform translate-x-full [&.open]:translate-x-0 transition-transform duration-300 ease-out-cubic" data-lenis-prevent>
        <button id="side-form-close" className="absolute vw-top [--top:38] vw-right [--right:16] mini:[--right:28] tablet:[--right:40] desktop:[--right:48]" data-side-form-action="close">
          <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.859375" width="33.1924" height="33.8995" rx="16.5962" fill="#346DE1" />
            <line x1="21.5459" y1="13.2129" x2="12.3535" y2="22.4053" stroke="white" />
            <line x1="12.3536" y1="13.2168" x2="21.5459" y2="22.4091" stroke="white" />
          </svg>
        </button>
        <div id="side-form-label" className="subtitle text-deepBlue uppercase">
          {data.subtitle}
        </div>
        <div id="side-form-title" className="h3 mini:vw-max-w mini:[--max-w:276] desktop:[--max-w:324]">
          {data.title}
        </div>
        <form onSubmit={handleSubmit} action="" className="flex flex-col vw-gap [--gap:24]">
          <fieldset className="flex flex-col vw-gap [--gap:24] tablet:grid grid-cols-2">
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] tablet:col-span-2">
              <label htmlFor="full_name"><FormattedMessage id="form.name" /></label>
              <input type="text" name="candidate_name" id="fullName" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" />
              {formErrors.candidate_name && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.candidate_name}</div>}
            </div>
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6]">
              <label htmlFor="email"><FormattedMessage id="form.email" /></label>
              <input type="email" name="candidate_email" id="email" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
              {formErrors.candidate_email && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.candidate_email}</div>}
            </div>
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6]">
              <label htmlFor="phone"><FormattedMessage id="form.phone" /></label>
              <input type="tel" name="candidate_phone" id="phone" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="tel" />
              {formErrors.candidate_phone && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.candidate_phone}</div>}
            </div>
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] tablet:col-span-2">
              <label htmlFor="CV"><FormattedMessage id="form.your_cv" />*</label>
              <div className="file relative">
                <input
                  type="file"
                  name="resume"
                  id="CV"
                  onChange={handleChange}
                  className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30"
                  accept="application/pdf"
                />
                <svg className="vw-w [--w:18] vw-h [--h:16] absolute top-1/2 vw-right [--right:24] transform -translate-y-1/2 pointer-events-none" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9.125V14.125H16.8247V9.125" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.98242 11.2422L8.98242 1.21617" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.0376 2.95312L9.09439 1.00352L11.1512 2.95312" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {formErrors.resume && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.resume}</div>}
            </div>
            {/* <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] tablet:col-span-2">
              <label htmlFor="linkedin">Your url linkedin</label>
              <input type="url" name="candidate_linkedin_url" id="linkedin" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" placeholder="Url" inputMode="url" />
            </div> */}
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] tablet:col-span-2">
              <label htmlFor="message"><FormattedMessage id="form.your_message" /></label>
              <textarea name="message" id="message" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] vw-h [--h:140] resize-none body-paragraph rounded w-full border border-teal-950/30"></textarea>
            </div>
            <div id="side-form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] tablet:col-span-2">
              <div className="checkbox">
                <input type="checkbox" name="consent[]" id="consent0" required />
                <label htmlFor="consent0" dangerouslySetInnerHTML={{ __html: formPrivacyLabel }} style={{ display: "block" }}></label>
              </div>
            </div>
          </fieldset>
          <div id="side-form-submit">
            <button type="submit" disabled={isSubmitting} target="_self" className="inline-flex text-center justify-center items-center max-tablet:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-deepBlue text-white">
              <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>
                {isSubmitting ? <BeatLoader size={10} color="#FFFFFF" /> : <FormattedMessage id="form.send" />}
              </span>
            </button>
          </div>
        </form>
      </div>
      <div id="sideFormBackdrop" className="z-40 pointer-events-none opacity-0 [.open+&]:opacity-100 [.open+&]:pointer-events-auto fixed top-0 left-0 w-screen h-screen bg-deepBlue/60 transition-opacity duration-300 ease-out-cubic" data-side-form-action="close">
        {/* Backdrop content (if any) */}
      </div>
    </div>
  );
}

export default SideForm;
