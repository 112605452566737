import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";
import { Box, Container, Paper, Step, StepLabel, Stepper  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import Informations from "./JobOfferCreation/Informations";
import Description from "./JobOfferCreation/Description";
import Scheduler from "./JobOfferCreation/Scheduler";
import { capitalize, getFormattedDate } from "bundles/common/utils/helpers";
import sh from "bundles/common/utils/sh";
import { updateCurrentUser } from "bundles/common/reducers/user";
import { useBlocker } from "bundles/common/hooks/useBlocker";

const steps = [
  { code: "informations", value: 1 },
  { code: "description", value: 2 },
  { code: "scheduler", value: 3 },
];

const useStyles = makeStyles((theme) => {
  return {
    stepper: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "32px",
      "& .MuiStepLabel-iconContainer": {
        padding: "0px",
      },
      "& .MuiStepIcon-root": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.main} !important`,
      },
      "& .MuiStepIcon-active": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.lighter} !important`,
      },
      "& .MuiStepIcon-completed": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.main} !important`,
      },
      "& .Mui-disabled .MuiStepIcon-root": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.text.disabled} !important`,
      },
    },
    stepContainer: {
      marginTop: "64px !important",
      marginLeft: "24px !important",
      paddingLeft: "48px !important",
      paddingRight: "48px !important",
      paddingBottom: "32px !important",
    },
    stepBox: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "8px",
    },
    title: {
      marginBottom: "24px !important",
    },
    textfield: {
      minWidth: "280px !important",
    },
    formItem: {
      marginBottom: "16px !important",
      overflowX: "auto",
    },
    chipSelection: {
      overflowX: "hidden !important",
      marginBottom: "0px !important",
    },
    textareaItem: {
      marginBottom: "32px !important",
      alignItems: "left !important",
    },
    footerButtons: {
      width: "200px !important",
      marginLeft: "24px !important",
      marginBottom: "16px !important",
    },
  };
});

const JobOfferCreation = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const currentUser = useSelector((state) => state.current_user);

  const isEdit = window.location.pathname.includes("edit");

  const initValues = (initialJobOffer) => {
    return {
      offer_language: initialJobOffer?.offer_language || "",
      category_id: initialJobOffer?.category_id || null,
      job_title: initialJobOffer?.job_title || "",
      job_status: initialJobOffer?.job_status || "",
      region: initialJobOffer?.region || null,
      city: initialJobOffer?.city || "",
      languages: initialJobOffer?.languages || [],
      min_salary: initialJobOffer?.min_salary || (initialJobOffer?.salary_type === "no-salary" ? null : 0),
      max_salary: initialJobOffer?.max_salary || (initialJobOffer?.salary_type === "no-salary" ? null : 0),
      salary_type: initialJobOffer?.salary_type || "yearly",
      summary: initialJobOffer?.summary || "",
      description_title: initialJobOffer?.description_title || "",
      description: initialJobOffer?.description || "",
      role_title: initialJobOffer?.role_title || "Role",
      role: initialJobOffer?.role || "",
      profile_title: initialJobOffer?.profile_title || "Profile",
      profile: initialJobOffer?.profile || "",
      offer_title: initialJobOffer?.offer_title || "Offer",
      offer: initialJobOffer?.offer || "",
      benefits: initialJobOffer?.benefits || [],
      offer_status: initialJobOffer?.offer_status || "draft",
      scheduled_at: initialJobOffer?.scheduled_at || null
    };
  };

  const [loading, setLoading] = useState(false);
  const [jobOffer, setJobOffer] = useState({});
  const [values, setValues] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [autosave, setAutosave] = useState(false);
  const [lastAutosave, setLastAutosave] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);

  const fetchJobOffer = () => {
    setLoading(true);
    sh.get(`/internal/job_offers/${params.id}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        if (res?.data) {
          const fetchedJobOffer = res.data;
          if (
            (fetchedJobOffer.user.id === currentUser.id || currentUser["admin?"]) &&
            fetchedJobOffer.offer_status !== "archived"
          ) {
            setJobOffer(fetchedJobOffer);
            setValues(initValues(fetchedJobOffer));
            setCurrentStep(steps.find((s) => s.value == 1));
          } else {
            enqueueSnackbar("You don't have permission to edit this ad.", {
              variant: "error",
            });
            setTimeout(() => {
              window.location.href = "/internal";
            }, 800);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreviousStep = () => {
    const previousStep = steps.find((step) => step.value == currentStep.value - 1);
    if (previousStep) {
      setCurrentStep(previousStep);
    }
  };

  const handleNextStep = () => {
    const nextStep = steps.find((step) => step.value == currentStep.value + 1);
    if (nextStep) {
      setCurrentStep(nextStep);
      let stepper = document.getElementById("job-offer-creation-stepper-id");
      stepper?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  const handleStep = (stepValue) => {
    const targetStep = steps.find((step) => step.value == stepValue);

    if (targetStep) {
      setCurrentStep(targetStep);
      let stepper = document.getElementById("job-offer-creation-stepper-id");
      stepper?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

  };

  const handleSave = (publish = false, schedule = false) => {
    setLoading(true);
    if (jobOffer?.id) {
      updateJobOffer(publish, schedule).then(() => {
        onSaveSuccessful();
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      createJobOffer(publish, schedule).then(() => {
        onSaveSuccessful();
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const determineOfferStatus = (publish, schedule) => {
    if (publish) return "online";
    if (schedule) return "scheduled";
    return "draft";
  };

  const createJobOffer = (publish, schedule) => {
    let persistedValues = values;
    persistedValues.offer_status = determineOfferStatus(publish, schedule);
    return sh.post("/internal/job_offers", {
      authenticity_token: authenticityToken,
      job_offer: persistedValues
    });
  };

  const updateJobOffer = (publish, schedule) => {
    let persistedValues = values;
    persistedValues.offer_status = determineOfferStatus(publish, schedule);
    return sh.put(`/internal/job_offers/${jobOffer.id}`, {
      authenticity_token: authenticityToken,
      job_offer: persistedValues
    });
  };

  const saveAsDraft = () => {
    if (jobOffer?.id) {
      return sh.put(`/internal/job_offers/update_draft/${jobOffer.id}`, {
        authenticity_token: authenticityToken,
        job_offer: values
      });
    } else {
      return sh.post("/internal/job_offers/save_draft", {
        authenticity_token: authenticityToken,
        job_offer: values
      });
    }
  };

  const onSaveSuccessful = () => {
    setIsDirty(false);
    updateCurrentUser(dispatch, authenticityToken);
    enqueueSnackbar("Job offer saved!", {
      variant: "success",
    });
    navigate("/internal");
  };

  useBlocker(() => {}, isDirty);

  useEffect(() => {
    let stepper = document.getElementById("job-offer-creation-stepper-id");
    stepper?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    if (!jobOffer?.id && params.id) {
      fetchJobOffer();
    } else if (!jobOffer?.id) {
      setValues(initValues());
      setCurrentStep(steps.find((s) => s.value == 1 ));
    }
  }, []);

  useEffect(() => {
    if (isEqual(values, initValues(jobOffer))) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [values, jobOffer]);

  useEffect(() => {
    const autosave = setInterval(function() {
      setAutosave(true);
    }, 60 * 500); // runs every 30 seconds
    return () => {
      setAutosave(false); // turn autosave off
      clearInterval(autosave); // clear autosave on dismount
    };
  }, []);

  useEffect(() => {
    if (autosave && isDirty && (!jobOffer?.id || jobOffer.offer_status == "draft")) {
      saveAsDraft().then((res) => {
        if (res?.data?.id) {
          setIsDirty(false);
          setJobOffer(res.data);
          updateCurrentUser(dispatch, authenticityToken);
          let now = new Date(Date.now());
          setLastAutosave(getFormattedDate(now));
          let navigateUrl = isEdit ? `/internal/edit_ad/${res.data.id}` : "/internal/new_ad";
          navigate(navigateUrl);
        }
      });
      setAutosave(false);
    }
  }, [autosave, isDirty]);

  return (
    <Container maxWidth="md">
      {currentStep &&
        <Stepper
          activeStep={currentStep.value - 1}
          className={classes.stepper}
          color="primary"
          id="job-offer-creation-stepper-id"
        >
          {steps.map((step) => {
            return (
              <Step key={step.key}>
                <StepLabel
                  style={{ cursor: step.value <= currentStep.value - 1 ? "pointer" : "default" }}
                  {...(step.value <= currentStep.value - 1 && {
                    onClick: () => handleStep(step.value)
                  }
                  )}
                >
                  <span style={{ paddingLeft: "8px" }}>{capitalize(step.code)}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      }
      <Paper elevation={3} style={{ marginTop: "32px", marginBottom: "64px", padding: "16px 32px" }}>
        <Box>
          {!isDirty && (lastAutosave || getFormattedDate(jobOffer?.updated_at)) ?
            <span style={{display: "flex", justifyContent: "end", alignItems: "center", fontSize: "12px"}}>
              <TaskAltIcon style={{ color: "green", marginRight: "4px"}} /><i>Last saved: {lastAutosave || getFormattedDate(jobOffer?.updated_at)}</i>
            </span>
            :
            <span style={{display: "flex", justifyContent: "end", alignItems: "center", fontSize: "12px"}}>
              <AssignmentLateIcon style={{ color: "#f44336", marginRight: "4px"}} /><i>Not saved yet</i>
            </span>
          }
        </Box>
        {currentStep &&
          <>
            {currentStep.code == "informations" && (
              <Informations
                jobOffer={jobOffer}
                values={values}
                setValues={setValues}
                handleNextStep={handleNextStep}
              />
            )}
            {currentStep.code == "description" && (
              <Description
                values={values}
                setValues={setValues}
                handleNextStep={handleNextStep}
                goPreviousStep={handlePreviousStep}
              />
            )}
            {currentStep.code == "scheduler" && (
              <Scheduler
                jobOffer={jobOffer}
                loading={loading}
                setLoading={setLoading}
                values={values}
                setValues={setValues}
                handleSave={handleSave}
                goPreviousStep={handlePreviousStep}
              />
            )}
          </>
        }
      </Paper>
    </Container>
  );
};

export default JobOfferCreation;
