import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { useSnackbar } from "notistack";
import slugify from "slugify";
import { Autocomplete, Avatar, Box, Button, Link, Typography, Chip, FormControl, Container, InputLabel, Divider, Select, Checkbox, ListItemText, ListSubheader, OutlinedInput, MenuItem, Switch, FormControlLabel, Pagination, TextField } from "@mui/material";
import sh from "bundles/common/utils/sh";
import { statusChipColor } from "bundles/common/utils/constants";
import { belgianRegions, frenchRegions } from "bundles/common/utils/constants";
import { capitalize, formatRegion, organizeCategoriesByDepartment, getFormattedDate } from "bundles/common/utils/helpers";
import { duplicateOffer } from "bundles/common/utils/actions/job_offers";
import ConfirmModal from "bundles/Internal/components/ConfirmModal";
import NoData from "internal/no-data.svg";

const Jobs = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentUser = useSelector((state) => state.current_user);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState({ allSelected: false, selectedCount: 0, selectedRows: [] });
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState([]);
  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  const [organizedCategories, setOrganizedCategories] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [onlineSwitch, setOnlineSwitch] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [showIndeed, setShowIndeed] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    }
  };

  const columns = [
    {
      name: "Job title",
      selector: row => row.job_title,
      grow: 1.5,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.job_title < rowB.job_title) return -1;
        if (rowA.job_title > rowB.job_title) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      }
    },
    {
      name: "City",
      selector: row => row.city,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.city < rowB.city) return -1;
        if (rowA.city > rowB.city) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      }
    },
    {
      name: "Category",
      selector: row =>  row.category?.name ? <Chip label={row.category?.name} /> : "",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.category.name < rowB.category.name) return -1;
        if (rowA.category.name > rowB.category.name) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      }
    },
    {
      name: "Salary",
      selector: row => {
        if (row.salary_type === "no-salary") {
          return <span style={{ color: "grey" }}>No salary</span>;
        }
        if ((row.min_salary || row.min_salary === 0) && row.max_salary) {
          return row.min_salary === row.max_salary
            ? `${row.min_salary}${row.salary_type === "yearly" ? "K/year" : "€/day"}`
            : `${row.min_salary}-${row.max_salary}${row.salary_type === "yearly" ? "K/year" : "€/day"}`;
        }
        return "/";
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.salary_type === "yearly" && rowB.salary_type !== "yearly") {
          return -1;
        }
        if (rowA.salary_type !== "yearly" && rowB.salary_type === "yearly") {
          return 1;
        }
        if (rowA.min_salary !== rowB.min_salary) {
          return rowB.min_salary - rowA.min_salary;
        }

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      },
    },
    {
      name: "Status",
      selector: row => {
        let value = capitalize(row.offer_status);
        if (!row.valid_offer) value += " - Unfinished";
        return <Chip color={statusChipColor[row.offer_status] || "default"} label={value} />;
      },
      center: true
    },
    {
      name: "Created by",
      selector: row =>  {
        return (
          <Box style={{ display: "flex", columnGap: "4px", alignItems: "center"}}>
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt={`${row.user?.first_name} ${row.user?.last_name}`}
              src={row.user?.avatar_url}
            />
            <Box>{`${row.user?.first_name} ${row.user?.last_name}`}</Box>
          </Box>
        );
      },
      center: true
    },
    {
      name: "Publication date",
      selector: row => getFormattedDate(row.published_at, false),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateA - dateB;
      },
      id: "published_at"
    },
    {
      name: <img src={"/static/images/logos/Indeed.svg"} alt="Indeed" style={{ height: "12px", marginBottom: "2px" }} />,
      selector: row => row.offer_status === "online" ? (
        <Checkbox
          checked={row.on_indeed}
          onChange={(event) => handleIndeedCheckboxChange(event, row)}
          color="primary"
          sx={{ cursor: "pointer" }}
        />
      ) : "/",
      center: true,
      width: "80px",
    },
    {
      name: "",
      selector: row => {
        const slugifyOptions = {
          lower: true,
          strict: true,
          remove: /[*+~.()'"!:@]/g
        };
        const jobTitleSlug = slugify(row.job_title, slugifyOptions);
        const publicURL = window.location.origin;
        const jobUrl = `${publicURL}/jobs/${jobTitleSlug}/${row.id}`;
        return (<Box>
          {row.offer_status == "online" &&
          <>
            <Button
              size="small"
              variant="text"
              onClick={() => window.open(jobUrl, "_blank")}
            >
              → See Job
            </Button>
            <Button
              size="small"
              variant="text"
              onClick={() => { navigate(`/internal/edit_ad/${row.id}`, { state: { jobOffer: row } }); }}
            >
              Edit
            </Button>
          </>
          }
          {row.offer_status == "archived" &&
            <Button
              size="small"
              variant="text"
              disabled={loading}
              onClick={() => duplicateOffer(row.id, setLoading, authenticityToken, onDuplicateSuccessful, onUpdateFail)}
            >
              Duplicate
            </Button>
          }
        </Box>);
      },
      width: "180px",
      right: true,
    },
  ];

  const fetchEmployees = () => {
    sh.get("internal/all_employees").then((res) => {
      setEmployees(res?.data?.employees || []);
    });
  };

  const fetchCategories = () => {
    sh.get("/categories", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedCategories = res?.data?.categories;
      setOrganizedCategories(organizeCategoriesByDepartment(fetchedCategories));
    });
  };

  const onSelectedRowsChange = (params) => {
    setSelectedJobs(params);
  };

  const onDuplicateSuccessful = () => {
    fetchAllJobs();
    enqueueSnackbar("Offer succesfully duplicated!", {
      variant: "success",
    });
  };

  const onUpdateFail = () => {
    enqueueSnackbar(
      "Oops, an error occured.",
      { variant: "error" }
    );
  };

  const onBulkArchive = () => {
    setIsArchiveConfirmModalOpen(true);
  };

  const onArchiveConfirmed = () => {
    let archives = selectedJobs.selectedRows.filter((offer) => offer.offer_status != "draft");
    let deletions = selectedJobs.selectedRows.filter((offer) => offer.offer_status == "draft");
    sh.post("/internal/job_offers/bulk_archive", {
      authenticity_token: authenticityToken,
      ids: {
        archives: archives.map((offer) => offer.id),
        deletions: deletions.map((offer) => offer.id)
      }
    }).then(() => {
      fetchAllJobs();
      setClearSelectedRows(true);
      setIsArchiveConfirmModalOpen(false);
      enqueueSnackbar("Offers succesfully archived/deleted!", {
        variant: "success",
      });
      setClearSelectedRows(false);
      setSelectedJobs({ allSelected: false, selectedCount: 0, selectedRows: [] });
    }).catch(() => onUpdateFail());
  };

  const handleIndeedCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;

    sh.post("/internal/job_offers/indeed_toggle", {
      authenticity_token: authenticityToken,
      job_offer_id: row.id,
      on_indeed: isChecked
    }).then(() => {
      enqueueSnackbar(
        `Job offer ${isChecked ? "pushed to" : "removed from"} Indeed feed!`,
        { variant: "success" }
      );
      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.id === row.id ? { ...job, on_indeed: isChecked } : job
        )
      );
    }).catch(() => {
      onUpdateFail();
    });
  };

  const options = {
    selectedCategories,
    selectedRegions,
    selectedEmployee,
    onlineSwitch,
    showIndeed,
    page: currentPage,
  };

  const fetchAllJobs = ({
    page = currentPage,
    selectedCategories,
    selectedRegions,
    selectedEmployee,
    onlineSwitch,
    showIndeed,
  } = {}) => {
    setLoading(true);

    const params = {
      authenticity_token: authenticityToken,
      selected_categories: selectedCategories?.map(category => category.id),
      selected_regions: selectedRegions,
      selected_employee: selectedEmployee?.id,
      online_jobs: onlineSwitch,
      indeed_jobs: showIndeed,
      page,
    };

    sh.get("internal/job_offers", { params })
      .then((res) => {
        setJobs(res?.data?.data);
        setPageCount(res.data?.pagy?.pages);
        setCount(res?.data?.pagy?.count);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (event) => {
    const new_page = Number(event.target.innerText);
    setCurrentPage(new_page);
    fetchAllJobs({
      ...options,
      page: new_page
    });
  };

  const handleRegionChange = (selectedRegions) => {
    setCurrentPage(1);
    fetchAllJobs({
      ...options,
      selectedRegions: selectedRegions
    });
    setSelectedRegions(selectedRegions);
  };

  const handleCategoryChange = (selectedCategories) => {
    setCurrentPage(1);
    fetchAllJobs({
      ...options,
      selectedCategories: selectedCategories
    });
    setSelectedCategories(selectedCategories);
  };

  const handleSelectEmployee = (selectedEmployee) => {
    setCurrentPage(1);
    fetchAllJobs({
      ...options,
      selectedEmployee: selectedEmployee
    });
    setSelectedEmployee(selectedEmployee);
  };

  const onSwitchChange = (event) => {
    const checked = event.target.checked;
    setCurrentPage(1);
    fetchAllJobs({
      ...options,
      onlineSwitch: checked
    });
    setOnlineSwitch(checked);
  };

  const handleIndeedSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setShowIndeed(isChecked);

    const newOptions = isChecked ? { ...options, showIndeed: true } : { ...options, showIndeed: false };

    fetchAllJobs(newOptions);
  };

  const removeAllFilters = () => {
    setCurrentPage(1);
    setSelectedCategories([]);
    setSelectedRegions([]);
    setSelectedEmployee(null);
    setOnlineSwitch(true);
    setShowIndeed(false);
    fetchAllJobs({
      onlineSwitch: true
    });
  };

  useEffect(() => {
    if (!currentUser || !currentUser["admin?"]) {
      navigate("/internal");
    } else {
      fetchAllJobs({
        selectedCategories,
        selectedRegions,
        selectedEmployee,
        onlineSwitch,
        showIndeed,
        page: currentPage,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    fetchCategories();
    fetchEmployees();
  }, []);

  return (
    <>
      <Container maxWidth="xl" style={{ paddingTop: "32px" }}>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h1>All jobs</h1>
          <Button variant="squared" color="primaryContained" href="/internal/new_ad">New job offer</Button>
        </Box>
        <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
          <Button
            size="small"
            variant="squared"
            color="primaryOutlined"
            onClick={onBulkArchive}
            disabled={selectedJobs.selectedCount < 1}
          >
            Archive/Delete{" "}{selectedJobs.selectedCount > 0 ? `(${selectedJobs.selectedCount})` : ""}
          </Button>
          <Box style={{ display: "flex", columnGap: "16px"}}>
            <FormControlLabel
              control={<Switch checked={onlineSwitch} onChange={onSwitchChange} />}
              label={
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography><strong>{count} {onlineSwitch ? "online" : "archived"} jobs</strong></Typography>
                </Box>
              }
            />
            <FormControlLabel
              control={<Switch checked={showIndeed} onChange={handleIndeedSwitchChange} />}
              label={<img src="/static/images/logos/Indeed.svg" alt="Indeed" style={{ height: "24px", marginBottom: "4px" }} />}
            />
            <Box>
              <FormControl sx={{ width: "240px", ".MuiOutlinedInput-root": { height: "40px" } }}>
                <InputLabel id="demo-multiple-checkbox-label" sx={{ top: selectedCategories.length === 0 ? "-6px" : "0" }}>Select Categories</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedCategories}
                  onChange={(event) => handleCategoryChange(event.target.value)}
                  input={<OutlinedInput label="Select Categories" />}
                  renderValue={(selected) => selected.map(item => item.name).join(", ")}
                >
                  {Object.entries(organizedCategories).map(([department, categories]) => [
                    <ListSubheader key={department}>{department}</ListSubheader>,
                    ...categories.map((category) => (
                      <MenuItem key={category.id} value={category} sx={{ padding: "0px 8px" }}>
                        <Checkbox checked={selectedCategories.includes(category)} />
                        <ListItemText primary={category.name} />
                      </MenuItem>
                    ))
                  ])}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ width: "240px", ".MuiOutlinedInput-root": { height: "40px" } }}>
                <InputLabel id="demo-multiple-checkbox-label" sx={{ top: selectedRegions.length === 0 ? "-6px" : "0" }}>Select Regions</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedRegions}
                  onChange={(event) => handleRegionChange(event.target.value)}
                  input={<OutlinedInput label="Select Regions" />}
                  renderValue={(selected) => selected.map(item => formatRegion(item)).join(", ")}
                >
                  <ListSubheader>Belgium</ListSubheader>
                  {belgianRegions.map((name) => (
                    <MenuItem key={name} value={name} sx={{ padding: "0px 8px" }}>
                      <Checkbox checked={selectedRegions.indexOf(name) > -1} />
                      <ListItemText primary={formatRegion(name)} />
                    </MenuItem>
                  ))}
                  <ListSubheader>France</ListSubheader>
                  {frenchRegions.map((name) => (
                    <MenuItem key={name} value={name} sx={{ padding: "0px 8px" }}>
                      <Checkbox checked={selectedRegions.indexOf(name) > -1} />
                      <ListItemText primary={formatRegion(name)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Autocomplete
                size="small"
                options={employees}
                value={selectedEmployee}
                onChange={(params, value) => handleSelectEmployee(value) }
                renderOption={(props, option) => {
                  return (
                    <Box style={{ display: "flex", columnGap: "4px", alignItems: "center"}} {...props} key={`${option.id}${option.last_name}`}>
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        alt={`${option?.first_name} ${option?.last_name}`}
                        src={option?.avatar_url}
                      />
                      <Box>{`${option?.first_name} ${option?.last_name}`}</Box>
                    </Box>
                  );
                }}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                filterOptions={(options, params) => {
                  let query = params?.inputValue?.toLowerCase() || "";
                  return options.filter((user) => {
                    return user?.first_name?.toLowerCase()?.includes(query) ||
                      user?.last_name?.toLowerCase()?.includes(query) ||
                      user?.regional_department?.name?.toLowerCase()?.includes(query);
                  });
                }}
                sx={{ width: "240px", ".MuiAutocomplete-inputRoot": { height: "40px" } }}
                renderInput={(params) => <TextField {...params} label="Select an employee" />}
                renderTags={() => {}}
              />
            </Box>
            <Box style={{ display: "flex", alignItems: "center", marginRight: "32px" }}>
              <Link onClick={removeAllFilters} sx={{ cursor: "pointer" }}>Clear all</Link>
            </Box>
          </Box>
        </Box>
        <Divider style={{ marginTop: "8px" }}/>
        <DataTable
          noDataComponent={
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", rowGap: "16px"}}>
              <h3>No jobs created yet</h3>
              <img style={{ width: "45%", marginTop: "64px"}} src={NoData} />
            </Box>
          }
          columns={columns}
          data={jobs}
          defaultSortFieldId="published_at"
          defaultSortAsc={false}
          responsive
          striped
          highlightOnHover
          pointerOnHover
          selectableRows
          progressPending={loading}
          onSelectedRowsChange={onSelectedRowsChange}
          clearSelectedRows={clearSelectedRows}
          onRowClicked={(row) => navigate(`/internal/edit_ad/${row.id}`)}
          customStyles={customStyles}
        />
        {!loading &&
          <Box display="flex" justifyContent="center" mt={4} mb={4}>
            <Pagination count={pageCount} page={currentPage} onChange={handlePageChange} />
          </Box>
        }
      </Container>
      <ConfirmModal
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        handleConfirm={onArchiveConfirmed}
        label={`You're about to archive/delete ${selectedJobs.selectedCount} offers.`}
      />
    </>

  );
};

export default Jobs;
