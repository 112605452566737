import React from "react";
import { useIntl } from "react-intl";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import TitleHero from "../components/partials/TitleHero";
import Content from "../components/partials/Content";

const Terms = () => {
  const intl = useIntl();

  const titleData = {
    title: intl.formatMessage({ id: "footer.terms.title" }),
  };

  const contentData = [
    {
      title: intl.formatMessage({ id: "terms.preamble_title" }),
      html: intl.formatMessage({ id: "terms.preamble_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.definition_scope_title" }),
      html: intl.formatMessage({ id: "terms.definition_scope_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.personal_data_title" }),
      html: intl.formatMessage({ id: "terms.personal_data_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.intellectual_property_title" }),
      html: intl.formatMessage({ id: "terms.intellectual_property_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.cookies_title" }),
      html: intl.formatMessage({ id: "terms.cookies_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.responsibility_title" }),
      html: intl.formatMessage({ id: "terms.responsibility_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.hyperlinks_title" }),
      html: intl.formatMessage({ id: "terms.hyperlinks_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.access_website_title" }),
      html: intl.formatMessage({ id: "terms.access_website_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.integrity_clauses_title" }),
      html: intl.formatMessage({ id: "terms.integrity_clauses_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.jurisdiction_title" }),
      html: intl.formatMessage({ id: "terms.jurisdiction_html" }),
    },
    {
      title: intl.formatMessage({ id: "terms.complementary_documents_title" }),
      html: intl.formatMessage({ id: "terms.complementary_documents_html" }),
    }
  ];


  return (
    <div id="contact">
      <Menu />
      <Header />

      <main>
        <TitleHero data={titleData} />
        <Content data={contentData} />
      </main>

      <Footer />
    </div>
  );
};

export default Terms;
