import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const WhyUsDetailed = () => {
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const whyUsData = {
    subtitle: intl.formatMessage({ id: "why_us_detailed.subtitle" }),
    title: intl.formatMessage({ id: "why_us_detailed.title" }),
    content: intl.formatMessage({ id: "why_us_detailed.content" }),
    button: {
      url: `/${i18nLocale}/contact`,
      title: intl.formatMessage({ id: "why_us_detailed.button.title" }),
      target: "_self"
    },
    cards: [
      {
        icon: { url: "/static/images/icons/why-us-detailed-1.svg", alt: "" },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_1.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-2.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_2.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-3.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_3.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-4.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_4.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-5.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_5.label" })
      }
    ],
    image1: {
      url: "/static/images/whyuscompany-whyus-1.jpg",
      alt: intl.formatMessage({ id: "why_us_detailed.image1_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-whyus-1_640.webp",
        medium: "/static/images/scaled/whyuscompany-whyus-1_1280.webp",
        large: "/static/images/scaled/whyuscompany-whyus-1_2560.webp"
      }
    },
    image2: {
      url: "/static/images/whyuscompany-whyus-2.jpg",
      alt: intl.formatMessage({ id: "why_us_detailed.image2_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-whyus-2_640.webp",
        medium: "/static/images/scaled/whyuscompany-whyus-2_1280.webp",
        large: "/static/images/scaled/whyuscompany-whyus-2_2560.webp"
      }
    }
  };

  return (
    <section id="whyus-detailed" className="relative text-darkGreen">
      <div className="gg-container vw-py [--py:50] mini:[--py:70] tablet:[--py:110] laptop:[--py:130]">
        <div className="gg-grid !vw-gap-y [--gap-y:100]">
          <div className="col-start-1 col-span-12 mini:col-start-1 mini:col-span-11 tablet:col-start-1 tablet:col-span-12 laptop:col-start-3 laptop:col-span-11 desktop:col-start-3 desktop:col-span-10 flex flex-col vw-gap [--gap:24] mini:[--gap:32] laptop:[--gap:42]">
            <div className="subtitle uppercase text-deepBlue fadeInUp">
              {whyUsData.subtitle}
            </div>
            <h3 className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: whyUsData.title }} />
            <div className="richtext body-paragraph fadeInUp">
              {whyUsData.content}
            </div>
            <div className="fadeInUp">
              <a href={whyUsData.button.url} className="button max-mini:w-full border-lightBlue bg-lightBlue text-darkGreen hover:border-deepBlue hover:bg-deepBlue hover:text-white">
                <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="button-text">
                  {whyUsData.button.title}
                </span>
              </a>
            </div>
          </div>
          <div className="col-start-1 col-span-12 mini:col-start-14 mini:col-span-11 tablet:col-start-15 tablet:col-span-9 laptop:col-start-17 laptop:col-span-6 flex flex-col vw-gap [--gap:32] mini:vw-pt mini:[--pt:42] tablet:[--pt:56] desktop:[--pt:64]">
            {whyUsData.cards.map((card, index) => (
              <div key={index} className="flex items-start vw-gap [--gap:24] mini:[--gap:36] [&:not(:last-child)]:vw-pb [&:not(:last-child)]:[--pb:32] [&:not(:last-child)]:border-b [&:not(:last-child)]:border-lightBlue fadeInUp">
                <div className="vw-w [--w:62] shrink-0 grow-0">
                  <img
                    className="w-full aspect-square object-contain"
                    src={card.icon.url}
                    alt={card.icon.alt}
                  />
                </div>
                <div className="body-paragraph">
                  {card.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Image 1 and Image 2 rendering */}
      <div id="whyus-detailed-image-1" className="overflow-hidden vw-w [--w:276] mini:[--w:436] tablet:[--w:580] laptop:[--w:732] desktop:[--w:864] aspect-[274/240] mini:aspect-[436/352] tablet:aspect-[580/468] laptop:aspect-[732/577] desktop:aspect-[864/694] vw-rounded-tr [--rounded-tr:90] mini:[--rounded-tr:150] laptop:vw-mt laptop:[--mt:-160] parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={whyUsData.image1.sizes.thumbnail}
          srcSet={`${whyUsData.image1.sizes.thumbnail} 640w, ${whyUsData.image1.sizes.medium} 1280w, ${whyUsData.image1.sizes.large} 2560w`}
          sizes="100vw"
          alt={whyUsData.image1.alt}
          loading="lazy"
        />
      </div>

      <div id="whyus-detailed-image-2" className="overflow-hidden vw-w [--w:190] mini:[--w:276] tablet:[--w:372] laptop:[--w:444] desktop:[--w:540] aspect-[190/178] mini:aspect-[276/261] tablet:aspect-[372/347] laptop:aspect-[444/428] desktop:aspect-[540/515] vw-rounded-bl [--rounded-bl:90] mini:[--rounded-bl:150] ml-auto vw-mt [--mt:-40] mini:[--mt:-195] tablet:[--mt:-260] laptop:[--mt:-220] desktop:[--mt:-340] parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={whyUsData.image2.sizes.thumbnail}
          srcSet={`${whyUsData.image2.sizes.thumbnail} 640w, ${whyUsData.image2.sizes.medium} 1280w, ${whyUsData.image2.sizes.large} 2560w`}
          sizes="100vw"
          alt={whyUsData.image2.alt}
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default WhyUsDetailed;
